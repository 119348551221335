import React from 'react'
import Helmet from 'react-helmet'

import {
  Layout,
  BannerSmall,
  TextBox,
  Image,
  BlogSlider,
  Breadcrumbs,
  BannerLinks,
  Appointment
} from '../../components'
import BannerImage from '../../assets/projektuebersicht.jpg'
import Georg from '../../assets/georg_neu.png'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'

import { generateBreadcrumbsLinks } from '../../utils/index'

import navigation from '../../assets/navigation.xs.gif'
import passwort from '../../assets/passwort.gif'
import checkliste from '../../assets/checkliste.xs.gif'

const MicroInteraction = () => {
  const links = generateBreadcrumbsLinks(
    '/blog/micro-interaction',
    'Micro-Interactions'
  )
  return (
    <Layout>
    <Helmet
      title="Micro-Interactions"
      meta={[
        {
          name: 'description',
          content:
            'Sogenannte Micro-Interactions (kleine mini Interaktionen für den User) haben einen Zweck: den User einer Website oder App zu erfreuen und ihm zu helfen, die Ergebnisse seiner Handlungen besser zu verstehen.'
        },
        {
          name: 'keywords',
          content:
            'Micro-Interaction holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Micro-Interaction, User Experience, User-Experience, UX, UX Website'
        },
        {
          name: 'image',
          content:
            'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
        },
        {
          property: 'og:description',
          content:
            'Sogenannte Micro-Interactions (kleine mini Interaktionen für den User) haben einen Zweck: den User einer Website oder App zu erfreuen und ihm zu helfen, die Ergebnisse seiner Handlungen besser zu verstehen.'
        },
        {
          property: 'og:image',
          content:
            'https://www.holzweg.com/static/projektuebersicht-3b0d0cca7e33df5e55e7b68d779cc633.jpg'
        }
      ]}
    />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Micro-Interactions
          </span>
        }
      />
      <Breadcrumbs backLinkAlias=" " links={links} />
      <TextBox
        title={
          <span>
            Verbesserung der User Experience
          </span>
        }
        text={
          <span>
            <p>
              Sogenannte Micro-Interactions (kleine mini Interaktionen für den
              User) haben einen Zweck:{' '}
              <b>den User einer Website/App zu erfreuen!</b> <br />
              Micro-Interactions geben dem User Feedback zu seinem Status,
              helfen ihm, Ergebnisse seiner Handlungen besser zu verstehen und
              geben ihm das Gefühl der direkten Einflussnahme auf der Seite.
            </p>
            <p>
              Micro-Interactions sind winzige meist animierte Designelemente,
              die oft mit viel Liebe zum Detail designed und entwickelt werden.
            </p>
            <ul>
              <li>Sie verbessern die Website-Navigation</li>
              <li>Sie erleichtern dem User die Interaktion mit der Website</li>
              <li>
                Sie geben dem User sofortiges und relevantes Feedback zu einer
                abgeschlossenen Aktion
              </li>
              <li>Sie geben dem User Tipps</li>
              <li>
                Sie kommunizieren Informationen zu bestimmen Elementen z.B. ob
                diese interaktiv sind oder nicht
              </li>
              <li>Sie machen die Benutzererfahrung viel lohnender</li>
              <li>Sie lenken die Aufmerksamkeit des Users</li>
              <li>
                Und zum Schluss: sie sind einfach nice und machen Ihre Seite
                emotionaler
              </li>
            </ul>
            <h3>Noch nicht davon überzeugt?</h3>
            <p>Hier ein paar Beispiele:</p>
          </span>
        }
      />
      <Image
        image={passwort}
        title="Abbildung 1: Hier wird bei der Eingabe eines Passworts überprüft, wie gut dessen Qualität ist. Die Qualität wird auch mittels Icon ausgegeben und gibt damit ein sofortiges visuelles Feedback für den User."
      />
      <Image
        image={checkliste}
        title="Abbildung 2: Sobald in der Checkliste ein Item zum Löschen ausgewählt wird, erscheint der Text durchgestrichen. Das hilft dem User zu erkennen, was noch auf der Liste ist, bevor er die zu löschenden Items endgültig löscht."
      />
      <Image
        image={navigation}
        title="Abbildung 3: Die Micro-Interaction bei der Navigation verdeutlicht, wann die Navigation geöffnet ist und schließbar wird."
      />
      <TextBox
        title=""
        text={
          <span>
            <h3>Wollen Sie auch Micro-Interactions auf Ihrer Website?</h3>
            <p>Dann kontaktieren Sie uns doch sofort:</p>
          </span>
        }
      />
      <Appointment image={Georg} />
      <BlogSlider cards={cards} singleRow={true} title="Weitere Blogeinträge" />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default MicroInteraction
